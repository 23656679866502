<template>
  <div class="segment">
    <div class="content narrow" style="max-width: 960px">
       <!-- <div class="header">BEGINNER'S GUIDE TO <span class="text-highlight">BERGET</span></div> -->

      <div class="post column">
        <div class="post-banner">
          <img src="@/assets/pagebanner-guide.jpg">
        </div>
        <div class="post-content-wrapper">

          <div class="post-header">Booking a ticket</div>
          <pre class="post-content">
            <span style="text-decoration: underline;"><strong>Welcome to our Beginner's Guide to Berget Events!</strong></span>
            This guide is made to explain all of the elements of the huge airsoft event that is Berget, all the way from booking a ticket to leaving the game area after the game.
            Since you are already here at the guide, we assume that you know what berget is, otherwise you can read more about it <a href="../info">here</a>.

            Berget Events is not your typical weekend game. It's a 4 day long airsoft adventure taking place in an area over 3x3km large.
            With this comes a need for you to prepare and equip yourself differently, and this is where this guide comes in.
            Berget can be an overwhelming experience at first, so the more prepared you are - The more fun you and your friends will have!

            <span style="text-decoration: underline;"><strong>Before booking a ticket</strong></span>
            Before you book your ticket to the next Berget Event, the first step is to decide on what team you want to join. Different teams have different requirements that you need to meet to be able to play on that specific team.
            Some examples of team requirements are: Uniform type, Weapons and Playstyle.  Please read thru the requirements for the team you wish to join and make sure you are able to meet the requirements before booking a ticket. Also make sure to book on the same team as your friends if you want to play together.

            <span style="text-decoration: underline;"><strong>Booking a ticket</strong></span>
            The release of the tickets for Berget is usually in late october or early november. We will announce the release usually 2-3 weeks ahead so please check our website for updates. The game is always held in last week in june after the swedish midsummer.

            The tickets are often sold out in a very short period of time, so make sure you have decided which team you and your friends want to play on. If you miss out on tickets to your team, unpaid tickets will be released after 30-45 days so there is still a chance to get a hand on ticket to your team after the initial ticket-release.
          </pre>
        </div>

      </div>

      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Preparing for Berget</div>
          <pre class="post-content">
            <strong>To get the most out of Berget you need three things to work. Your body, mind and your gun. Here are some things to consider.</strong>

            1.) Make sure you have walked your shoes in, don't buy new shoes just before the game, there will be serious risk of blisters.

            2.) Before going to the event. Fill your backpack with water-bottles with the same weight as your combat pack you are planning to wear in the field. Train to walk with that pack for 10-20 km, at least once a week before the event.

            3.) The summer can be hot in Sweden, when moving long distances in the game area you will be dehydrated. Make sure to bring at least 3 liters of water in the field and bring mineral tablets like "Resorb" or alike. Water can be refilled in base and at some points in the field.

            4.) The last thing you want is a non functional gun during Berget, and you don't want to spend time taking it apart in the field. Make sure your team has 1-2 spare guns with the same battery connector/gas/magazines available.

            5.) Print out the "Berget-rules", study them carefully when going to bed until you know every part. Then start reading the <a href="../forum/viewforum.php?f=97">veterans</a> part on the Berget-forum.

            6.) Mosquitoes. Especially the summers when it has rained a lot in June, will have a lot of mosquitoes after sunset. They are not dangerous and do not carry diseases. The best way to protect yourself is to wear full clothing and a mosquito net for your face when you sleep. You will also find mosquito repellent in all Swedish grocery stores and pharmacies.

            7.) Try to keep a positive attitude, be pragmatic and positive. If you have to criticize, make it constructive and do not be aggressive about it. Identify your chain of command and work with your platoon &amp; company commander. Work with the players on your side to ensure you all have a good game.  There is an old veterans saying: - "You only get out of Berget what you put into it!"
          </pre>
        </div>

      </div>

      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Arriving at Berget</div>
          <pre class="post-content">
            When you arrive to Berget-area, follow the signs to parkingspace to park your car, its is usually within 200-300 meters from Check-in. Take your guns from the parking to the check-in area and go to the check-in. You will find toilets, water, airsoft-shop, HPA-fillstation and you can buy soda and snacks/food in the Safezone.

            At check-in you will have to have your ticket-id, driverslicense or passport ready for things to go smoothly. We will register your arrival in our system and take a photo of you. After your check-in is registered, you will get the items you booked including armband and T-shirt.

            Next stop is the gun chroning. Bring all your guns to the chroning and make sure the gun has battery/gas/air/magazine and are loaded with the bullets you will use during the event.

            After registration and chroning are ready, you can take your car to your base (Follow team signs at roads). Unload your gear as quickly as possible and return the car to main parking in Safezone. The driver can use the Safezone Taxi service to get back to base or walk the 3-4 km.

            At the base campsite, find your commander or the berget-crew in your base to understand where to sleep or put up your tent. There will be 230v power outlets i central part of base, toilets, water and a small kiosk with snacks. There is also a command HQ where the HQ staff of your team will be working.

            NOTE !!!
            <span style="color: #ff0000;">Do not arrive to game area</span> before the offcial opening of safezone tuesday 09:00

            Why?

            <em>1.) We do not have any tents ready in the bases. Which means that people will demand that from us and we will have to put aside other important things to do arrange this.</em>

            <em>2.) No toilets in bases, people will start crapping in the forrest. Which will lead to complaints from the landowner.</em>

            <em>3.) There is no water. Which means that people will demand that from us and we will have to put aside other important things to arrange this.</em>

            <em>4.) We need to rent the game-area longer and have our personal working two+ days extra. In the end this means that we will need to raise the prices.</em>

            <em>5.) There is no fire extinguishers in bases &amp; no security personal which directly violates the contract for renting the game-area and the agreement with the police.</em>

            <em>6.) Pre-game scouting. People will start walking around the gamearea seeing secret objects and props several days before they will be used.</em>
          </pre>
        </div>

      </div>

      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">During the Game</div>
          <pre class="post-content">
            During the game, your team leader will be given daily missions or tasks by your team's top commander.

            The assignments are given to your group leader 1-3 times a day. It can be anything from guarding a crossroads, attacking an object or sabotaging something in the enemy base.

            Collaborate with your team members in the group to solve your task.

            Normally, the missions can last until late at night. As soon as you get the chance, make sure to rest, eat and sleep (even in the field).

            Your and the enemy's bases are offgame between 3:00 at night and 9:00 in the morning so that people can get some stable sleep. This means that no fighting or shooting may occur in the base during that time. You are also not allowed to scout or get into the opponent's base during off-game.

            6 little things you absolutely must not forget when you go into the gamearea to play.

            1.) Safety glasses
            2.) Dead-rag (orange cloth you put on your head when you are shot)
            3.) Your bracelet with your player id.
            4.) Ingame-map or GPS
            5.) Your phone.
            6.) Waterbottle

            Note!
            Remember to keep safety googles on at all times.
          </pre>
        </div>

      </div>

      <div class="post">
        <div class="post-content-wrapper">

          <div class="post-header">Timeline before the event</div>
          <pre class="post-content">
            After the Christmas and New Year holidays, we have got a good picture of how the number of players placed themselves on the various teams. During Christmas and New Year, we will accept applications for Leaders on the various teams.

            Use our Discord @ <a href="https://discord.gg/NW8TB3EDB9">https://discord.gg/NW8TB3EDB9</a> to be able to meet other players and chat with your team-mates and crew.

            Once these have been recruited, we will announce them and then the creation of companies, platoons, etc. will begin. It is with your commander that you talk to about whether you or your team want to belong to a specific platoon or company.

            We expect to announce the game area in January but the digital map and base locations will come much later in the spring when the snow is gone and the crew can determine exact locations. The route to check-in and parking will be announced earlier.

            Last of all, your commander will receive his Operation order where your main mission and secondary tasks will be divided. Keep in mind your commanders will be wary of sharing too much information pre-game as there is a risk of this leaking to the opposing team.

            In terms of planning, you will make a base plan in advance regarding which companies live where in the base, how your defense facilities will be structured.

            If you play in a group, make sure your group leader finds out early on who your higher commander is.

            Although Berget has a good logistics chain for water toilets and tents. Make sure to prepare carefully for the event and read through all the information on our website. Train regularly with the equipment you will use and make sure that you have walked many kilometers in the shoes you will use.

            Above all, keep in mind that we are out in the woods under field conditions, things take longer, can be delayed and you can't expect the same service you can get in a city.
          </pre>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'BeginnersGuide'
}
</script>
